import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3e585f48&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorLogoFantini: require('/var/www/html/fantini/frontend/components/vector/logo/fantini/index.vue').default,Btn: require('/var/www/html/fantini/frontend/components/btn/index.vue').default,BtnMuted: require('/var/www/html/fantini/frontend/components/btn/muted/index.vue').default,BtnSolid: require('/var/www/html/fantini/frontend/components/btn/solid/index.vue').default,Collapse: require('/var/www/html/fantini/frontend/components/collapse/index.vue').default,Accordion: require('/var/www/html/fantini/frontend/components/accordion/index.vue').default,BtnPill: require('/var/www/html/fantini/frontend/components/btn/pill/index.vue').default,ModalNewsletter: require('/var/www/html/fantini/frontend/components/modal/newsletter/index.vue').default,BtnCircle: require('/var/www/html/fantini/frontend/components/btn/circle/index.vue').default,Block: require('/var/www/html/fantini/frontend/components/block/index.vue').default})
